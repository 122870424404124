import { uuid } from '../utils/uuid';
import { objectify } from '../utils/objectify';
import { IMap } from './generic.models';

export interface MollakSettings {
	url: string,
	id: string
}

// Mollak Community Model

export interface MollakCommunity {
	id: string,
	mollakName: string,
	arabicName: string,
	mollakId: string,
	status: 'Pending' | 'Approved' | 'Rejected',
	client?: string,
	managementCompanyId?:string,
}

const defaultMollakCommunity: Required<MollakCommunity> = {
	id: '',
	mollakName: '',
	arabicName: '',
	mollakId: '',
	status: 'Pending',
	client: '',
	managementCompanyId:'',
};

export const getDefaultMollakCommunity = () => {
	return { ...objectify( defaultMollakCommunity ), id: uuid() };
};

// Mollak Invoice Model

export interface MollakInvoice {
	id: string,
	description: string,
	period: string,
	budgetPeriod: string,
	community: string,
	unit: string,
	costCenter: string,
	invoiceNumber: string,
	type: string,
	journalDate: string,
	invoiceDate: string,
	dueDate: string,
	amount: number,
	vatAmount: number,
	amountGeneral: number,
	amountReserve: number
	items?: Partial<MollakInvoiceItem>[],
	isArchived: boolean,
	pdf: string,
	pdfSaved: boolean,
	mollakCostCenter: string,
}

const defaultMollakInvoice: Required<MollakInvoice> = {
	id: '',
	invoiceNumber: '',
	type: '',
	invoiceDate: '',
	journalDate: '',
	dueDate: '',
	amount: 0,
	amountGeneral: 0,
	amountReserve: 0,
	isArchived: false,
	vatAmount: 0,
	community: '',
	unit: '',
	costCenter: '',
	mollakCostCenter: '',
	items: [],
	pdf: '',
	pdfSaved: false,
	description: '',
	period: '',
	budgetPeriod: '',
};



export const getDefaultMollakInvoice = () => {
	return { ...objectify( defaultMollakInvoice ), id: uuid() };
};

// Mollak Invoice Item Model

export interface MollakInvoiceItem {
	id: string,
	name: string,
	arabicName: string,
	amount: number,
	vatAmount: number,
	vatPercentage: number,
	fund: string,
	description: string,
	account: string,
	invoice: string,
}

const defaultMollakInvoiceItem: Required<MollakInvoiceItem> = {
	id: '',
	name: '',
	arabicName: '',
	amount: 0,
	vatAmount: 0,
	vatPercentage: 0,
	fund: '',
	description: '',
	account: '',
	invoice: '',
};

export const getDefaultMollakInvoiceItem = () => {
	return { ...objectify( defaultMollakInvoiceItem ), id: uuid() };
};

// Mollak Receipt Model

export interface MollakReceipt {
	id: string,
	receiptNumber: string,
	journalDate: string,
	postingDate: string,
	amount: number,
	recordSource: string,
	community: string,
	unit: string,
	bankNumber: string,
	isArchived: boolean,
	lastFetchedOn: string,
	isVoided: boolean,
	noqodiInfo?:NoqodiInfo | null;
}
export interface NoqodiInfo{
	invoiceNumber: string,
	noqoodiReference: string,
	payoutBatchId: string,
	eresReference: number,
	generalFundAmount: number,
	reservedFundAmount: number,
	settlementDate: string,
	settlementAmount: number,
	settlementGeneralFund: number,
	settlementReservedFund: number  
 }
const defaultMollakReceipt: Required<MollakReceipt> = {
	id: '',
	receiptNumber: '',
	journalDate: '',
	postingDate: '',
	amount: 0,
	recordSource: '',
	community: '',
	unit: '',
	bankNumber: '',
	isArchived: false,
	lastFetchedOn: '',
	isVoided: false,
	noqodiInfo:null
};

export const getDefaultMollakReceipt = () => {
	return { ...objectify( defaultMollakReceipt ), id: uuid() };
};


// Mollak Unit Model

export interface MollakUnit {
	id: string,
	mollakId: string,
	community: string,
	mollakName: string,
	plot: string,
	suitArea: number,
	actualArea: number,
	balconyArea: number,
	area: number,
	building: string,
	buildingName: string,
	buildingArabicName: string,
}

const defaultMollakUnit: Required<MollakUnit> = {
	id: '',
	mollakId: '',
	community: '',
	mollakName: '',
	plot: '',
	suitArea: 0,
	actualArea: 0,
	balconyArea: 0,
	area: 0,
	building: '',
	buildingName: '',
	buildingArabicName: '',
};

export const getDefaultMollakUnit = () => {
	return { ...objectify( defaultMollakUnit ), id: uuid() };
};

// Mollak CC Model

export interface MollakCostCenter {
	id: string,
	mollakId: string,
	community: string,
	mollakName: string,
	arabicName: string,
	usage: string,
	arabicUsage: string,
}

const defaultMollakCostCenter: Required<MollakCostCenter> = {
	id: '',
	mollakId: '',
	community: '',
	mollakName: '',
	arabicName: '',
	usage: '',
	arabicUsage: '',
};

export const getDefaultMollakCostCenter = () => {
	return { ...objectify( defaultMollakCostCenter ), id: uuid() };
};

// Sync Models

export enum MollakSyncState {
	Pending = 0,
	Progress = 1,
	Complete = 1000,
	Failed = 1001,
}

export enum MollakResponseCode {
    DuplicatedReceipt_1 = '3042',
    DuplicatedReceipt_2 = '3051',
}

export interface MollakSyncLog {
	type: 'Error' | 'Progress' | 'Complete' | 'Warning',
	loggedOn: string,
	message: string,
}

export interface MollakSync {
	id: string,
	logs: IMap<MollakSyncLog>,
	state: MollakSyncState,
	type: string,
	requestedOn: string,
	completedOn: string,
	requestedBy: string,
}

const defaultMollakSync: Required<MollakSync> = {
	id: '',
	state: 0,
	logs: {},
	type: '',
	requestedOn: '',
	completedOn: '',
	requestedBy: '',
};

export const getDefaultMollakSync = (): Required<MollakSync> => {
	return { ...objectify( defaultMollakSync ), id: uuid() };
};

export interface MollakSyncCommunityBudgets extends MollakSync {
	community: string,
	type: 'sync_community_budgets',
}

export const getDefaultMollakSyncCommunityBudgets = (): Required<MollakSyncCommunityBudgets> => {
	return {
		...getDefaultMollakSync(),
		community: '',
		type: 'sync_community_budgets',
	};
};


export interface MollakSyncCommunityCostCenters extends MollakSync {
	community: string,
	type: 'sync_community_cost_centers',
}

export const getDefaultMollakSyncCommunityCostCenters = (): Required<MollakSyncCommunityCostCenters> => {
	return {
		...getDefaultMollakSync(),
		community: '',
		type: 'sync_community_cost_centers',
	};
};

export interface MollakSyncBulkReconcile extends MollakSync {
	community: string,
	type: 'sync_bulk_reconcile_journal',
}

export const getDefaultMollakSyncBulkReconcile = (): Required<MollakSyncBulkReconcile> => {
	return {
		...getDefaultMollakSync(),
		community: '',
		type: 'sync_bulk_reconcile_journal',
	};
};
export interface MollakSyncCommunityUnits extends MollakSync {
	community: string,
	type: 'sync_community_units',
}

export const getDefaultMollakSyncCommunityUnits = (): Required<MollakSyncCommunityUnits> => {
	return {
		...getDefaultMollakSync(),
		community: '',
		type: 'sync_community_units',
	};
};

export interface MollakSyncCommunityUnitsOwners extends MollakSync {
	community: string,
	type: 'sync_community_units_owners'
}

export const getDefaultMollakSyncCommunityUnitsOwners = (): Required<MollakSyncCommunityUnitsOwners> => {
	return {
		...getDefaultMollakSync(),
		community: '',
		type: 'sync_community_units_owners',
	};
};

export interface MollakSyncCommunityReceipts extends MollakSync {
	community: string,
	type: 'sync_community_receipts',
}

export const getDefaultMollakSyncCommunityReceipts = (): Required<MollakSyncCommunityReceipts> => {
	return {
		...getDefaultMollakSync(),
		community: '',
		type: 'sync_community_receipts',
	};
};

export interface MollakSyncCommunityInvoices extends MollakSync {
	community: string,
	costCenter?: string,
	quarter?: string,
	type: 'sync_community_invoices',
}

export const getDefaultMollakSyncCommunityInvoices = (): Required<MollakSyncCommunityInvoices> => {
	return {
		...getDefaultMollakSync(),
		community: '',
		type: 'sync_community_invoices',
		costCenter: '',
		quarter: '',
	};
};

export interface MollakSyncCommunities extends MollakSync {
	client: string,
	type: 'sync_communities',
}

export const getDefaultMollakSyncCommunities = (): Required<MollakSyncCommunities> => {
	return {
		...getDefaultMollakSync(),
		client: '',
		type: 'sync_communities',
	};
};


export interface MollakBudget {
	id: string;
	community: string;
	start: string;
	end: string;
	mollakId: string;
	versions: any;
}
const defaultMollakBudget: Required<MollakBudget> = {
	versions: '',
	start: '',
	mollakId: '',
	id: '',
	end: '',
	community: '',
}
export const getDefaultMollakBudget = (): Required<MollakBudget> => objectify( defaultMollakBudget );
export interface MollakBudgetResponse {
	serviceChargeGroupId: string;
	budgetPeriodCode: string;
	budgetPeriodTitle: string;
	budgetPeriodFrom: string;
	budgetPeriodTo: string;
	budgetItems: MollakBudgetResponseItem[];
}
export interface MollakBudgetResponseItem {
	categoryCode: string;
	categoryName: EnglishArabicName;
	subCategoryCode: string;
	subCategoryName: EnglishArabicName;
	serviceCode: string;
	serviceName: EnglishArabicName;
	totalCost: number;
	vatAmount: number;
}
export interface EnglishArabicName {
	englishName: string;
	arabicName: string;
}


export interface MollakRequest {
	client: string;
	body: MollakRequestBody;
}
interface MollakRequestBody {
	timeStamp: string;
	syncType: string;
	syncUrl: string;
	parameters: { key: string, value: string }[];
}
const defaultMollakRequest: MollakRequest = {
	body:
	{
		timeStamp: '',
		syncType: '',
		syncUrl: '',
		parameters: [],
	}
	,
	client: '',
}
export interface mollakInsertReceiptsData{
	newReceipt: MollakReceipt;
	communityId: string;
	userId: string;
	postedBy: string | undefined;
}
export const getDefaultMollakRequest = () => objectify( defaultMollakRequest )


export interface MollakPropertyGroup {
	masterCommunityName: MasterCommunityName
	projectName: ProjectName
	propertyGroupId: number
	propertyGroupName: PropertyGroupName
	status:  "Pending" | "Approved" | "Rejected" 
	merchantCode: any
	beneficiaryList: any[]
	managementCompany: ManagementCompany
 }
 
 export interface MasterCommunityName {
	englishName: string
	arabicName: string
 }
 
 export interface ProjectName {
	englishName: string
	arabicName: string
 }
 
 export interface PropertyGroupName {
	englishName: string
	arabicName: string
 }
 
 export interface ManagementCompany {
	id: number
	name: Name
 }
 
 export interface Name {
	englishName: string
	arabicName: string
 }
 
/* Master Community Models */

export enum MollakLandTypeEnum {
	'Residential' = '1',
	'Agricultural' = '2',
	'Industrial' = '3',
	'Commercial' = '4',
	'Public Facilities' = '5',
	'Government Authorities' = '6',
	'Hospitality' = '7',
	'Public Facility' = '8',
	'Educational' = '9',
	'Facilities' = '10',
	'Healthcare' = '11',
	'Recreational' = '12',
	'Open Space' = '13',
	'Water Body' = '14',
	'Utility' = '15',
	'Transportation' = '16',
	'Transport' = '17',
	'Future Development' = '18',
	'Mix' = '1001',
	'Retail' = '1002',
	'Parking' = '1003',
	'Store' = '1004',
	'Hotel' = '1005',
	'Office' = '1006',
}


export const MollakLandTypes: Record<string, keyof typeof MollakLandTypeEnum> ={
	[MollakLandTypeEnum['Residential']]: 'Residential',
	[MollakLandTypeEnum['Agricultural']]: 'Agricultural',
	[MollakLandTypeEnum['Industrial']]: 'Industrial',
	[MollakLandTypeEnum['Commercial']]: 'Commercial',
	[MollakLandTypeEnum['Public Facilities']]: 'Public Facilities',
	[MollakLandTypeEnum['Government Authorities']]: 'Government Authorities',
	[MollakLandTypeEnum['Hospitality']]: 'Hospitality',
	[MollakLandTypeEnum['Public Facility']]: 'Public Facility',
	[MollakLandTypeEnum['Educational']]: 'Educational',
	[MollakLandTypeEnum['Facilities']]: 'Facilities',
	[MollakLandTypeEnum['Healthcare']]: 'Healthcare',
	[MollakLandTypeEnum['Recreational']]: 'Recreational',
	[MollakLandTypeEnum['Open Space']]: 'Open Space',
	[MollakLandTypeEnum['Water Body']]: 'Water Body',
	[MollakLandTypeEnum['Utility']]: 'Utility',
	[MollakLandTypeEnum['Transportation']]: 'Transportation',
	[MollakLandTypeEnum['Transport']]: 'Transport',
	[MollakLandTypeEnum['Future Development']]: 'Future Development',
	[MollakLandTypeEnum['Mix']]: 'Mix',
	[MollakLandTypeEnum['Retail']]: 'Retail',
	[MollakLandTypeEnum['Parking']]: 'Parking',
	[MollakLandTypeEnum['Store']]: 'Store',
	[MollakLandTypeEnum['Hotel']]: 'Hotel',
	[MollakLandTypeEnum['Office']]: 'Office',
}

export enum MollakLandStatusEnum {
	'Vacant' = '0',
	'Under Construction' = '1',
	'Premised' = '2',
	'Unspecified' = '3',
}
export const MollakLandStatuses: Record<string, keyof typeof MollakLandStatusEnum> = {
	[MollakLandStatusEnum['Vacant']]: 'Vacant',
	[MollakLandStatusEnum['Under Construction']]: 'Under Construction',
	[MollakLandStatusEnum['Premised']]: 'Premised',
	[MollakLandStatusEnum['Unspecified']]: 'Unspecified',
}

/* Webhook Models */

// TODO After Getting Mollak IPs Add them to the array
export const mollakWhitelistedIPs: string[] = [
	'94.56.46.47','151.253.23.26','151.253.124.173','151.253.151.177', // ipv4
	'::ffff:94.56.46.47','::ffff:151.253.23.26','::ffff:151.253.124.173','::ffff:151.253.151.177', // ipv6
]

export enum SyncTypeEnum {
	paymentReceipt = 'payment_receipt',
	budgetApproved = 'budget_approved',
	invoiceGenerated = 'invoice_generated',
	ownershipChanged = 'ownership_changed',
	contractChanged = 'contract_changed',
	legalNoticeIssued = 'legal_notice_issued',
	ownerCommitteeFormed = 'owner_committee_formed',
	violationInvoice = 'violation_invoice'
 }

 export enum ParameterKeyEnum {
	managementCompanyId = 'managementCompanyId',
	propertyGroupId = 'propertyGroupId',
	mollakPropertyId = 'mollakPropertyId',
	periodCode = 'periodCode',
	receiptId = 'receiptId',
	quarterCode = 'quarterCode',
	serviceChargeGroupId = 'serviceChargeGroupId',
	legalNoticeId = 'legalNoticeId',
	ownerCommitteeNumber = 'ownerCommitteeNumber',
	contractNumber = 'contractNumber',
	contractStatus = 'contractStatus',
	invoiceNumber = 'invoiceNumber'
 }

export interface MollakWebhookMessage {
	id: string,
	timeStamp: string;
	syncType: SyncTypeEnum;
	parameters: {
		key: ParameterKeyEnum;
		value: string | number;
	}[];
}
export interface MollakWebhookMessagePG extends Omit<MollakWebhookMessage, 'parameters'> {
	parameters: string
}

const defaultMollakWebhookMessage: MollakWebhookMessagePG = {
	id: uuid(),
	timeStamp: '',
	syncType: SyncTypeEnum.paymentReceipt,
	parameters: ''
}

export const getDefaultMollakWebhookMessage = () => objectify( defaultMollakWebhookMessage );