import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppBadgeComponent } from "./app-badge.component";
import { ChipModule } from "primeng/chip";

@NgModule({
	declarations: [AppBadgeComponent],
	imports: [
		CommonModule,
		ChipModule
	],
	exports: [AppBadgeComponent]
})
export class AppBadgeModule {}
